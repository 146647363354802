
  import Vue, {PropType} from 'vue';
  import InfiniteLoading, {StateChanger} from 'vue-infinite-loading';
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import PwaInputFile from "@/components/PwaInputFile.vue";
  import {
    ChatMessage,
    defaultChatLimit,
    getChatMessages, LastChatMessage,
    onChatNewMessage,
    sendMsg,
    sendUsersLastMessage,
    sendUserCompetitionLastMessage,
    saveImage,
    removeMsg,
    onChatMessageRemove,
    markUsersLastMessageAsRead,
    markUsersCompetitionLastMessageAsRead,
    ChatData,
    CHAT_TYPES,
    ChatLabelMessage,
  } from "@/services/chat";
  import {containNotAllowedUrls, isIOS, linkify} from "@/utils/common";
  import moment from "moment-timezone";
  import DOMPurify from "dompurify";
  import {ROUTES} from "@/router/routes";
  import {ImageType} from "@/utils/form";
  import {BFormTextarea} from "bootstrap-vue";
  import {isNativeApp} from "@/utils/native-app";
  import {openApp, OpenAppLink} from "@/utils/app-launcher";

  export default Vue.extend({
    name: "pwa-chat-container",
    components: {
      InfiniteLoading,
      PwaIcon,
      PwaInputFile,
      PwaEmojiPicker: () => import("@/components/PwaEmojiPicker.vue"),
    },
    data() {
      return {
        msgList: [] as ChatMessage[],
        msgListLoaded: false,
        chatListenerUnsubscribe: null as () => void,
        removedMsgListenerUnsubscribe: null as () => void,
        disabledInfiniteScroll: false,
        competitionMatchTicket: null,
        competitionMatchTicketTimeout: null,
        hasMessage: false,
        rivalHasWriteInChat: false,
        chatDisabled: false,
        chatEndDateTimeout: null,
      }
    },
    props: {
      chatLimit: {
        type: Number,
        default: defaultChatLimit,
      },
      showRefereeButton: {
        type: Boolean,
        default: false,
      },
      showSendImageButton: {
        type: Boolean,
        default: false,
      },
      messages: {
        type: Array as PropType<ChatMessage[]>,
        default: null,
      },
      chatData: {
        type: Object as PropType<ChatData>,
        required: false,
        default: null,
      },
      messagesFromBottom: {
        type: Boolean,
        default: true,
        required: false,
      },
      deleteMessagesAvailable: {
        type: Boolean,
        required: false,
        default: false,
      },
      showMessageDate: {
        type: Boolean,
        required: false,
        default: true,
      },
      gameLink: {
        type: Object as PropType<OpenAppLink | null>,
        required: false,
        default: null,
      },
    },
    computed: {
      chatKey(): string {
        return this.chatData && this.chatData.chat_id
          ? this.chatData.chat_id
          : null;
      },
      competitionId(): number {
        return this.chatData && this.chatData.competition_id
          ? this.chatData.competition_id
          : null;
      },
      matchId(): number {
        return this.chatData && this.chatData.match_id
          ? this.chatData.match_id
          : null;
      },
      chatDataType(): string {
        return this.chatData && this.chatData.type
          ? this.chatData.type
          : null;
      },
      isChatDisabled(): boolean {
        return !this.chatKey || this.chatDisabled || (this.chatData && this.chatData.send_msg_disabled) || !this.chatConnected;
      },
      isTicketOpen(): boolean {
        return this.competitionMatchTicket && this.competitionMatchTicket.status === 1;
      },
      isTicketClose(): boolean {
        return this.competitionMatchTicket && this.competitionMatchTicket.status === 0;
      },
      isTicketAssign(): boolean {
        return this.competitionMatchTicket && this.competitionMatchTicket.referee_id;
      },
      showInfiniteLoading(): boolean {
        return this.chatKey && !this.disabledInfiniteScroll;
      },
      isUserBlocked(): boolean {
        return this.chatData && this.chatData.blocked;
      },
      chatConnected(): boolean {
        return this.$store.state.user.chat.connected;
      },
      isStaff(): boolean {
        return this.chatData && this.chatData.is_staff;
      },
      canDeleteMessages(): boolean {
        return this.deleteMessagesAvailable && this.isStaff;
      },
      showOpenGameButton(): boolean {
        let url = null;
        if (this.gameLink) {
          url = isNativeApp || isIOS
            ? this.gameLink.iosUrl
            : this.gameLink.androidUrl;
        }

        return Boolean(url);
      },
    },
    mounted(): void {
      this.onChatDataChange();
      if (this.showRefereeButton && this.competitionId && this.matchId) {
        this.getCompetitionMatchTicket();
      }
    },
    beforeDestroy(): void {
      this.stopAllListeners();
      this.clearChatEndDateTimeout();
    },
    methods: {
      async getMessages($state: StateChanger) {
        const lastKey = this.msgList.length > 0 ? this.msgList[0].key : null;
        try {
          const messages: any = await getChatMessages(this.chatKey, lastKey, this.chatLimit);
          let newMessages = 0;
          if (messages) {
            Object.keys(messages).reverse().forEach(key => {
              messages[key].key = key;
              messages[key].time = this.getMessageDate(messages[key].time);
              this.msgList.unshift(messages[key]);
              newMessages++;

              if (!this.rivalHasWriteInChat && messages[key].userId !== this.userId) {
                this.rivalHasWriteInChat = true;
              }
            });
          }
          if (!this.msgListLoaded) {
            this.msgListLoaded = true;
          }

          this.disabledInfiniteScroll = newMessages < this.chatLimit;
        } catch (e) {
          this.disabledInfiniteScroll = true;
        }
        $state.loaded();

        if (!this.chatListenerUnsubscribe) {
          this.initNewChatsListener();
        }

        if (!this.removedMsgListenerUnsubscribe) {
          this.initRemovedMsgListener();
        }

        if (lastKey === null) {
          this.scrollChatBottom();
          if (this.msgList.length > 0) {
            this.markAsRead();
          }
        }
      },
      async initNewChatsListener() {
        try {
          this.unsubscribeChatListener();
          this.chatListenerUnsubscribe = await onChatNewMessage(this.chatKey, ({val, key}) => {
            const lastItem = this.msgList.slice(-1).pop();
            if (!lastItem || lastItem.key !== key) {
              const isUserMessage = val.userId == this.userId;
              if (!isUserMessage) {
                const isScrollChatOnBottom = this.isScrollChatOnBottom();
                val.time = this.getMessageDate(val.time);
                this.msgList.push(val);
                this.rivalHasWriteInChat = true;
                if (isScrollChatOnBottom) {
                  this.scrollChatBottom();
                }
                this.markAsRead();
              } else {
                const currentMessage = this.msgList.find(({userId, messageId}) => userId === val.userId && messageId === val.messageId);
                if (!currentMessage) {
                  val.time = this.getMessageDate(val.time);
                  this.msgList.push(val);
                  this.scrollChatBottom();
                } else {
                  currentMessage.key = key;
                }
              }
            }
          });
        } catch (e) {
          this.unsubscribeChatListener();
        }
      },
      async initRemovedMsgListener() {
        try {
          this.unsubscribeRemovedMsgListener();
          this.removedMsgListenerUnsubscribe = await onChatMessageRemove(this.chatKey, ({ key }) => {
            this.msgList = this.msgList.filter(element => element.key !== key);
          });
        } catch (e) {
          this.unsubscribeRemovedMsgListener();
        }
      },
      getMessageDate(timestamp: number | string): string {
        return moment(timestamp).format('L LT');
      },
      isOwnMessage(userId: number | string): boolean {
        return this.userId == userId;
      },
      onSendMsg(e: KeyboardEvent) {
        e.preventDefault();
        if (this.isChatDisabled) {
          return;
        }

        const textarea = (this.$refs.textarea as BFormTextarea).$el as HTMLTextAreaElement;
        const message = textarea.value;

        if (e.shiftKey || e.altKey || e.ctrlKey) {
          textarea.value = message + '\n';
        } else {
          if (!message.trim()) {
            return;
          }

          if (containNotAllowedUrls(message)) {
            this.showToastError(this.$t('435'), this.$t('436'));
            return;
          }

          this.sendMsg(message);
        }
      },
      async onSendImg(imageData: ImageType) {
        if (this.isChatDisabled) {
          return;
        }

        try {
          const {data}: any = await this.$http.chat.postImagesCompress(imageData.image);
          imageData.image = data.data.image;
          const imageUrl = await saveImage(imageData, this.chatKey, this.userId);
          this.sendMsg(imageUrl, true);
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      sendMsg(message: string, isImage = false) {
        const textarea = (this.$refs.textarea as BFormTextarea).$el as HTMLTextAreaElement;
        if (!isImage) {
          textarea.value = '';
        }

        const toUser = this.chatData && this.chatData.to_user && this.chatData.to_user.id
          ? this.chatData.to_user
          : null;

        const {user} = this.$store.state.auth;
        const newMsg: ChatMessage = {
          messageId: Date.now().toString(),
          userId: user.id,
          userName: user.username,
          userAvatar: user.avatar,
          time: Date.now(),
          isStaff: this.isStaff,
          message,
          isImage,
        };

        if (toUser && toUser.id) {
          newMsg.toUserId = toUser.id;
        }

        const messagePosition = this.msgList.push({...newMsg, time: this.getMessageDate(newMsg.time)}) - 1;
        this.scrollChatBottom();

        sendMsg(newMsg, this.chatKey)
          .then(() => {/** Nothing To do */
          })
          .catch(() => {
            this.msgList = this.msgList.splice(messagePosition, 1);
          });

        if (this.chatDataType === CHAT_TYPES.COMPETITION && this.competitionId && this.matchId) {
          this.$http.chat.postUserCompetitionNotificationRival(this.competitionId, {
            info: {
              chat_id: this.chatKey,
              matchId: this.matchId,
              message: {
                message: newMsg.message,
                is_image: newMsg.isImage
              }
            }
          }).catch(() => {
            /** Nothing to do */
          });
        }

        if (toUser) {
          const lastMessageCurrentUser: LastChatMessage = {
            userId: toUser.id,
            userName: toUser.username,
            userAvatar: toUser.avatar,
            isRead: true,
            fromUserId: this.userId,
            messageId: newMsg.messageId,
            message: newMsg.message,
            time: newMsg.time,
            isImage: newMsg.isImage,
          };

          const {user} = this.$store.state.auth;
          const lastMessageToUser: LastChatMessage = {
            userId: this.userId,
            userName: user.username,
            userAvatar: user.avatar,
            isRead: false,
            fromUserId: this.userId,
            messageId: newMsg.messageId,
            message: newMsg.message,
            time: newMsg.time,
            isImage: newMsg.isImage,
          };

          if (this.chatDataType === CHAT_TYPES.COMPETITION && this.competitionId && this.matchId) {
            const costInscription = this.chatData.cost_inscription;
            const costInscriptionPromo = this.chatData.cost_inscription_promo;
            const gameName = this.chatData.game_name;
            const gameIcon = this.chatData.game_icon;
            const competitionData = {costInscription, costInscriptionPromo, gameName, gameIcon};
            sendUserCompetitionLastMessage({...lastMessageCurrentUser, ...competitionData}, this.userId, this.competitionId, this.matchId)
              .catch(() => {
                /** Nothing to do */
              });

            sendUserCompetitionLastMessage({...lastMessageToUser, ...competitionData}, toUser.id, this.competitionId, this.matchId)
              .catch(() => {
                /** Nothing to do */
              });
          } else if (this.chatDataType === CHAT_TYPES.USERS) {
            sendUsersLastMessage(lastMessageCurrentUser, this.userId, toUser.id)
              .catch(() => {
                /** Nothing to do */
              });

            sendUsersLastMessage(lastMessageToUser, toUser.id, this.userId)
              .catch(() => {
                /** Nothing to do */
              });

            this.$http.chat.postUsersChatNotify({
              username: toUser.username,
              message: newMsg.message,
              is_image: isImage
            }).catch(() => {
              /** Nothing to do */
            });
          }
        }
      },
      async onAddRefereeClick() {
        if (this.isChatDisabled || this.isTicketOpen) {
          return;
        }

        try {
          this.showLoader(true);
          const {data} = await this.$http.competition.postCompetitionTicket(this.competitionId, this.matchId);
          this.competitionMatchTicket = data.data;
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      async getCompetitionMatchTicket() {
        try {
          const {data} = await this.$http.competition.getCompetitionMatchTicket(this.competitionId, this.matchId);
          this.competitionMatchTicket = data.data;
          if (!this.isChatDisabled && this.showRefereeButton && this.competitionId && this.matchId) {
            this.competitionMatchTicketTimeout = setTimeout(this.getCompetitionMatchTicket, 30000);
          } else {
            this.competitionMatchTicketTimeout = null;
          }
        } catch (error) {
          // Nothing to do
        }
      },
      onEmojiSelected(emoji: string) {
        const bFormTextarea = this.$refs.textarea as BFormTextarea;
        const textarea = bFormTextarea.$el as HTMLTextAreaElement;
        const message = textarea.value;
        textarea.value = message + emoji;
        bFormTextarea.focus();
      },
      onUserClick(message: ChatMessage) {
        const {userName, userId} = message;
        if (userId && !this.isRefereeMessage(message)) {
          this.routerPush(ROUTES.profile.name, {username: userName});
        }
      },
      unsubscribeChatListener() {
        if (this.chatListenerUnsubscribe) {
          this.chatListenerUnsubscribe();
          this.chatListenerUnsubscribe = null;
        }
      },
      unsubscribeRemovedMsgListener() {
        if (this.removedMsgListenerUnsubscribe) {
          this.removedMsgListenerUnsubscribe();
          this.removedMsgListenerUnsubscribe = null;
        }
      },
      getUserNamePath(message: ChatMessage) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        const {userId, userName} = message;
        return (userId && userName)
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(userName))}`
          : '#';
      },
      scrollChatBottom(timeout = 700) {
        setTimeout(() => {
          const container = this.$refs.chatContainer as HTMLElement;
          container.scrollTop = container.scrollHeight;
        }, timeout);
      },
      isScrollChatOnBottom() {
        const container = this.$refs.chatContainer as HTMLElement;
        return (container.scrollHeight - container.scrollTop) === container.clientHeight;
      },
      getChatAvatar(message: ChatMessage) {
        return this.isRefereeMessage(message)
          ? this.getRefereeAvatar()
          : this.getAvatar(message.userAvatar);
      },
      getRefereeAvatar() {
        return this.getBrandImageSrc('referee-avatar.png');
      },
      isRefereeMessage(message: ChatMessage) {
        return message.userId === message.toUserId;
      },
      onDeleteMessageClick(msgKey: string) {
        if (this.canDeleteMessages) {
          this.showModal({
            title: this.$t('271'),
            message: this.$t('273'),
            onOkClick: () => this.deleteMessage(msgKey),
          });
        }
      },
      async deleteMessage(msgKey: string) {
        const numItems = this.msgList.length;
        const lastItem = numItems > 0 ? this.msgList[numItems - 1] : null;
        const isLastItem = lastItem && lastItem.key === msgKey;
        try {
          if (isLastItem) {
            this.msgList.pop();
          }
          await removeMsg(this.chatKey, msgKey);
        } catch (error) {
          if (isLastItem) {
            this.msgList.push(lastItem);
          }
        }
      },
      getMessage(message: ChatMessage): string {
        return message.isLabel && typeof message.message === 'object'
          ? this.getLabelMessage(message.message)
          : this.linkify(message.message);
      },
      getLabelMessage(message: ChatLabelMessage): string {
        let messageValue = '';
        try {
          const labelId = message.labelId;
          const values: Record<string, string> = {};
          if (message.values) {
            Object.keys(message.values).forEach(valueKey => {
              const valueData = message.values[valueKey];
              let valueText = '';

              if (valueData.text !== undefined) {
                valueText = valueData.text;
              } else if (valueData.labelId) {
                valueText = this.$t(valueData.labelId);
              } else if (valueData.money !== undefined) {
                valueText = this.formatPrice(valueData.money, true, 2, true);
              }

              values[valueKey] = valueText;
            });
          }

          messageValue = DOMPurify.sanitize(this.$t(labelId, values), {ALLOWED_TAGS: ['b', 'br', 'a'], ALLOWED_ATTR: ['href'], KEEP_CONTENT: true});
        } catch (e) {
          // Nothing to do
        }

        return messageValue;
      },
      linkify(value: string): string {
        return linkify(DOMPurify.sanitize(value, {ALLOWED_TAGS: [], ALLOWED_ATTR: [], KEEP_CONTENT: true}), this.$t('655'));
      },
      onMessageClick(event: MouseEvent) {
        let link: HTMLBaseElement = null;
        const element = event.target as HTMLBaseElement;
        const parentElement = element.parentElement as HTMLBaseElement;
        if (element.tagName.toUpperCase() === 'A') {
          link = element;
        } else if (parentElement.tagName.toUpperCase() === 'A') {
          link = parentElement;
        }

        if (link) {
          const elementHref = link.getAttribute('href');
          if (elementHref.charAt(0) === '/') {
            event.preventDefault();
            event.stopPropagation();
            this.$router.push((this.routeLang ? ('/' + this.routeLang) : '') + elementHref);
          } else {
            const elementTarget = link.getAttribute('target');
            if (elementTarget === '_blank') {
              event.preventDefault();
              event.stopPropagation();
              this.openLink(elementHref);
            }
          }
        }
      },
      restartChatData() {
        this.stopAllListeners();
        this.msgList = [];
        this.disabledInfiniteScroll = false;
        this.msgListLoaded = false;
        this.competitionMatchTicket = null;
        if (this.showRefereeButton && this.competitionId && this.matchId) {
          this.getCompetitionMatchTicket();
        }
      },
      stopAllListeners() {
        this.unsubscribeChatListener();
        this.unsubscribeRemovedMsgListener();
        if (this.competitionMatchTicketTimeout) {
          clearTimeout(this.competitionMatchTicketTimeout);
          this.competitionMatchTicketTimeout = null;
        }
      },
      markAsRead() {
        if (this.chatKey) {
          const toUserId = this.chatData && this.chatData.to_user && this.chatData.to_user.id
            ? this.chatData.to_user.id
            : null;

          if (this.competitionId && this.matchId) {
            markUsersCompetitionLastMessageAsRead(this.userId, this.competitionId, this.matchId);
          } else if (toUserId) {
            markUsersLastMessageAsRead(this.userId, toUserId);
          }
        }
      },
      onChatDataChange() {
        if (this.chatData) {
          if (this.chatData.send_msg_disabled === true) {
            this.chatDisabled = true;
            this.clearChatEndDateTimeout();
          } else if (this.chatData.send_msg_disabled === false) {
            this.chatDisabled = false;
            const chatEnabledEndDateTimeout = this.chatData.chat_enabled_end_date_timeout;
            this.setChatEndDateTimeout(chatEnabledEndDateTimeout);
          } else {
            this.chatDisabled = false;
            this.clearChatEndDateTimeout();
          }
        } else {
          this.chatDisabled = false;
          this.clearChatEndDateTimeout();
        }
      },
      setChatEndDateTimeout(chatEnabledEndDateTimeout: number) {
        this.clearChatEndDateTimeout();
        if (chatEnabledEndDateTimeout > 0) {
          setTimeout(() => {
            this.chatDisabled = true;
          }, chatEnabledEndDateTimeout);
        }
      },
      clearChatEndDateTimeout() {
        if (this.chatEndDateTimeout) {
          clearTimeout(this.chatEndDateTimeout);
          this.chatEndDateTimeout = null;
        }
      },
      goToCompetition() {
        this.routerPush(ROUTES.competition.name, {competitionId: this.competitionId});
      },
      onOpenGameClick() {
        if (this.gameLink) {
          openApp(this.gameLink);
        }
      }
    },
    watch: {
      messages() {
        if (this.messages.length > 0) {
          this.disabledInfiniteScroll = true;
          this.msgList = this.messages.map(message => ({...message, time: this.getMessageDate(message.time)}));
        }
      },
      chatData() {
        this.onChatDataChange();
      },
      chatKey() {
        this.restartChatData();
      },
      chatConnected() {
        if (this.chatConnected) {
          this.restartChatData();
        }
      },
    },
  });
